export const CONSTRUCTOR_URL = 'https://configurator.woodandwashi.eu';
export const LIBRARY_URL = 'https://library.woodandwashi.eu';
export const API_URL = 'https://api.woodandwashi.eu/api';

export const dutchedVersion = true;

export const collections = [
  {
    value: 'Elegance',
    name: 'Core',
    combi: ['flow']
  },
  {
    value: 'Excellence',
    name: 'Comfort',
    combi: ['rollo', 'flow', 'banner', 'panel']
  },
  {
    value: 'Exquisite',
    name: 'Custom',
    combi: ['rollo', 'flow', 'banner', 'panel']
  },
  {
    value: 'Style',
    name: 'Prime'
  },
  {
    value: 'Select',
    name: 'Premium'
  },
  {
    value: 'Superb',
    name: 'Prestige',
    combi: ['rollo', 'flow', 'banner', 'panel']
  }
];
